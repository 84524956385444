<template>

    <fullscreen>
        <div class="table-box p-3" v-if="data.dataList.length > 0">
            <div class="bg-white">
                <div class="d-flex align-items-start flex-wrap">
                    <div class="flex-grow-1">
                        <h2 class="mb-1">{{ getProjectData.project_name ? getProjectData.project_name : 'N/A' }}</h2>
                        <p class="">( {{ getProjectData.serial ? getProjectData.serial : 'N/A' }} )</p>
                    </div>
                    <div class="d-flex lex-wrap deliverable-matrix-button-group align-items-start bg-white py-3">
                        <button v-if="getProjectData.status != 'Completed'" class="btn btn-primary me-2" @click="addMilestone">PROJECT MILESTONE </button>
                        <router-link :to="`/projects/${project_id}/document-selection`"
                                     class="btn btn-primary me-2">DOCUMENT WIZARD
                        </router-link>
                        <button class="btn btn-primary me-2" @click="generateExcle('excel')">
                            <font-awesome-icon :icon="['fas', 'file-excel']"></font-awesome-icon>
                            <span class="ms-2">EXPORT</span>
                        </button>
                        <button class="btn btn-primary" v-fullscreen>
                            <font-awesome-icon :icon="['fas', 'expand']"></font-awesome-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="scrollable-content">

                <section class="title-table d-flex border-start sticky-top">
                    <div class="d-flex flex-column text-center border-top border-bottom">
                        <div class="table-head border-bottom border-end pt-2 bg-white">
                            Document
                        </div>
                        <div class="table-subhead d-flex flex-fill">
                            <div class="large-cell border-end">Deliverable</div>
                            <div class="large-cell border-end">Description</div>
                            <div class="large-cell border-end">Content Item</div>
                        </div>
                    </div>

                    <div class="d-flex flex-column border-top border-bottom text-center bg-white"
                         v-if="data.milestones.length > 0" >
                        <div class="table-head border-bottom border-end pt-2">
                            Milestones
                        </div>
                        <div class="table-subhead d-flex flex-fill">
                            <div class="small-cell border-end" v-for="(milestone, m_id) in data.milestones" :key="m_id">
                                 {{ milestone.name }} <br/>
                                 <small>({{ milestone.milestoneDate }})</small>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column text-center border-top border-bottom">
                        <div class="table-head border-bottom border-end pt-2 bg-white">
                            Review Process
                        </div>
                        <div class="table-subhead d-flex flex-fill">
                            <div class="large-cell border-end">Author</div>
                            <div class="large-cell border-end">Review</div>
                            <div class="large-cell border-end">Approval</div>
                        </div>
                    </div>

                    <div class="d-flex flex-column text-center border-top border-bottom">
                        <div class="table-head border-bottom border-end pt-2 bg-white"></div>
                        <div class="table-subhead d-flex flex-fill">
                            <div class="small-cell border-end">IVDR <br>Technical</div>
                            <div class="small-cell border-end">DHF</div>
                            <div class="small-cell border-end">DMR</div>
                            <div class="small-cell border-end">DHR</div>
                            <div class="small-cell border-end">Ad. Project</div>

                            <div class="large-cell border-end">Document ID</div>
                            <div class="large-cell border-end">SUBDOC</div>
                            <div class="large-cell border-end">Document Version</div>
                            <div class="large-cell border-end">Document Status</div>
                            <div class="large-cell border-end">Responsible</div>
                            <div class="large-cell border-end">Legal Requirement</div>
                        </div>
                    </div>
                </section>


                <div class="accordion deliverable-matrix-data-wrapper"
                     :id="`accordionWrapperOne_${index}`"
                     v-for="(result, index) in data.dataList" :key="index">
                    <div class="deliverable-matrix-data pb-2">
                        <div class="accordion-header accordion-control d-flex align-items-center" :id="`headerOne_${index}`">
                            <div class="me-2"
                                 v-if="invitationData.invitation_status != 'Invited' && data.permission.canDelete == true && getProjectData.status != 'Completed'">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'trash']" @click="deleteRow(result.id)"></font-awesome-icon>
                                </span>
                            </div>
                            <div>
                                <a @click="expandRow(result)"
                                   class="collasped py-4 px-2"
                                   type="button"
                                   data-bs-toggle="collapse"
                                   aria-expanded="false"
                                   :data-bs-target="`#itemOne_${index}`"
                                   :aria-controls="`itemOne_${index}`">
                                    <span>
                                        {{ result.document_name ? result.document_name : 'N/A' }}
                                        {{ result.version_no ? '(V:'+result.version_no+')' : 'N/A'}}
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div :id="`itemOne_${index}`"
                            class="accordion-collapse collapse"
                            :aria-labelledby="`headerOne_${index}`"
                            :data-bs-parent="`#accordionWrapperOne_${index}`">
                            <div class="d-flex border-start" v-if="Object.keys(data.expandRowData).length > 0"
                                 :class="index % 2 == 0 ? 'row-white' : 'row-alternate'">

                                <div class="large-cell text-break border-end border-top border-bottom">
                                    <p class="p-2">
                                        {{ data.expandRowData.document.name ? data.expandRowData.document.name : '' }}
                                    </p>
                                </div>
                                <div class="text-break border-end large-cell border-top border-bottom">
                                    <p class="p-2">
                                        {{ data.expandRowData.document.description ? data.expandRowData.document.description : '' }}
                                    </p>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="matrix-content-height p-2"
                                         v-for="(item, item_index) in data.expandRowData.items" :key="item_index"
                                         :class="item_index % 2 == 0 ? '' : 'bg-purple-lite border-top border-bottom'">
                                        <p>
                                            <router-link :to="`/projects/${setParams.project_id}/project-task/${item.id}`" class="text-break">
                                                {{ item.content_item.identifier ? item.content_item.identifier : 'N/A' }}
                                                {{ item.version_no ? '(V:'+item.version_no+')' : 'N/A' }}
                                            </router-link>
                                        </p>
                                    </div>
                                </div>

                                <div class="text-center border-end border-top border-bottom small-cell"
                                     v-if="data.milestones.length > 0"
                                     v-for="(milestone, m_id) in data.milestones" :key="m_id">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.document_milestone.includes(milestone.id) ? 1 : 0"
                                            @click="updateRow(result.id, '', 'milestones', {
                                                project_document_id: result.id,
                                                project_milestone_id: milestone.id
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="matrix-content-height p-2"
                                         :class="item_index % 2 == 0 ? '' : 'bg-purple-lite border-top border-bottom'"
                                         v-for="(item, item_index) in data.expandRowData.items" :key="item_index">
                                        <Multiselect
                                            @select="updateRow(result.id, item_index, 'content_items', {
                                                author_id: $event,
                                                milestones: data.expandRowData.document_milestone,
                                                content_type: 'author',
                                            })"
                                            placeholder="Select Author"
                                            :options="getUsers"
                                            :searchable="true"
                                            v-model="item.author.id"
                                            :disabled="data.checkUpdate || getProjectData.status == 'Completed'"
                                        >
                                        </Multiselect>
                                    </div>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="matrix-content-height p-2"
                                         :class="item_index % 2 == 0 ? '' : 'bg-purple-lite border-top border-bottom'"
                                         v-for="(item, item_index) in data.expandRowData.items" :key="item_index">
                                        <Multiselect
                                            @select="updateRow(result.id, item_index, 'content_items', {
                                                reviewer_id: data.expandRowData.items[item_index].reviewer_id,
                                                content_type: 'reviewer',
                                            })"
                                            placeholder="Select Reviewer"
                                            mode="tags"
                                            :options="getRoles"
                                            :searchable="true"
                                            v-model="item.reviewer_id"
                                            :disabled="data.checkUpdate || getProjectData.status == 'Completed'"
                                        >
                                        </Multiselect>
                                    </div>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="matrix-content-height p-2"
                                         :class="item_index % 2 == 0 ? '' : 'bg-purple-lite border-top border-bottom'"
                                         v-for="(item, item_index) in data.expandRowData.items" :key="item_index">
                                        <Multiselect
                                            @select="updateRow(result.id, item_index, 'content_items', {
                                                approver_id: data.expandRowData.items[item_index].approver_id,
                                                content_type: 'approver',
                                            })"
                                            placeholder="Select Approver"
                                            mode="tags"
                                            :options="getRoles"
                                            :searchable="true"
                                            v-model="item.approver_id"
                                            :disabled="data.checkUpdate || getProjectData.status == 'Completed'"
                                        >
                                        </Multiselect>
                                    </div>
                                </div>

                                <div class="text-center border-end small-cell border-top border-bottom">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.eu == 1 ? 1 : 0"
                                            @click="updateRow(result.id, '', 'document', {
                                                eu: data.expandRowData.eu == 1 ? '0' : '1'
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>
                                <div class="text-center border-end small-cell border-top border-bottom">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.dhf == 1 ? 1 : 0"
                                            @click="updateRow(result.id, '', 'document', {
                                                dhf: data.expandRowData.dhf == 1 ? '0' : '1'
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>
                                <div class="text-center border-end small-cell border-top border-bottom">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.dmr == 1 ? 1 : 0"
                                            @click="updateRow(result.id, '', 'document', {
                                                dmr: data.expandRowData.dmr == 1 ? '0' : '1'
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>
                                <div class="text-center border-end small-cell border-top border-bottom">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.dhr == 1 ? 1 : 0"
                                            @click="updateRow(result.id, '', 'document', {
                                                dhr: data.expandRowData.dhr == 1 ? '0' : '1'
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>
                                <div class="text-center border-end small-cell border-top border-bottom">
                                    <div class="py-2">
                                        <input class="form-check-input" type="checkbox"
                                            :checked="data.expandRowData.ad_project == 1 ? 1 : 0"
                                            @click="updateRow(result.id, '', 'document', {
                                                ad_project: data.expandRowData.ad_project == 1 ? '0' : '1'
                                            })"
                                           :disabled="data.checkUpdate || getProjectData.status == 'Completed'"/>
                                    </div>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">&nbsp;</div>
                                <div class="border-end large-cell border-top border-bottom">&nbsp;</div>

                                <div class="border-end text-center large-cell border-top border-bottom">
                                    <div class="py-2"><p>{{ '(V:'+data.expandRowData.document.version_no+')' }}</p></div>
                                </div>

                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="py-2"><p>&nbsp;</p></div>
                                </div>
                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="py-2"><p>&nbsp;</p></div>
                                </div>
                                <div class="border-end large-cell border-top border-bottom">
                                    <div class="py-2"><p>&nbsp;</p></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </fullscreen>


    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addMilestoneModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Project Milestone</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form ref="resetForm" @submit="formAction(data.inputFormData)"
                      v-slot="{ errors }" :validation-schema="schema">
                    <div class="modal-body">
                        <div class="row">
                            <div>
                                <label class="form-control-label">Milestone</label>
                                <Field as="select" class="form-select" aria-label="User-Milestone"
                                       v-model="data.inputFormData.milestone_id"
                                       :class="{'border-danger': errors.milestone_id}" name="milestone_id">
                                    <option :value="''">Milestone</option>
                                    <option :value="list.id" v-for="(list, key) in data.setMilestone" :key="key">
                                        {{ list.name }}
                                    </option>
                                </Field>
                                <span class="text-danger" v-if="errors.milestone_id">{{ errors.milestone_id }}</span>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Date</label>
                                <Field type="date" class="form-control" v-model="data.inputFormData.date"
                                       :class="{'border-danger': errors.date}" name="date"/>
                                <span class="text-danger" v-if="errors.date">{{ errors.date }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary"
                            :disabled="data.checkUpdate"
                        >Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {computed, inject, onMounted} from "vue";
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";
    import authHeader from "@/common/auth/auth-header";
    import Multiselect from '@vueform/multiselect'
    import {Field, Form} from "vee-validate";
    import * as Yup from "yup";

    export default {
        name: "DeliverableMatrix",
        components: { Multiselect, Field, Form },
        setup() {
            const { data, getAllData, openModal, storeFormData, downloadFile,
                deleteListData, resetForm, permissionResolver } = HelperFunction();
            const route = useRoute()
            const store = useStore()
            const axios = inject('axios')
            const toast = inject('toast')
            const project_id = route.params.id
            const company = store.getters.getSelectedBoard
            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const getProjectData = computed(() => store.getters.getProjectData);
            const userProfile = store.getters.getProfile
            data.expandRowData = []
            data.milestones = []
            data.users = []
            data.roles = []
            let setParams = {
                check: true,
                project_id: project_id,
                company_id: company.id
            };
            data.setURL = vueConfig.Project.DeliverableMatrix.DVEndPoint

            onMounted( () => {                
                onMountedGetData()
            })
            /** watch **/
            permissionResolver('deliverable-matrix');
            /** watch end **/

            async function onMountedGetData() {
                // ON CHANGE GETTING DATA ONLY
                await openModal(false, vueConfig.Project.Overview.MilestoneEndPoint, setParams)
                    .then((response) => {
                        data.milestones = []
                        if (response.data.data) {
                            response.data.data.forEach(function (row) {
                                data.milestones.push({id: row.id, name: row.milestone.name, milestoneDate: row.milestone_date});
                            });
                        }
                    })
                await getPrimaryData(`${vueConfig.Project.PrimaryDataEndPoint}/${project_id}`)

                // delete company id
                delete setParams.company_id
                await getAllData(`${data.setURL}?project_id=${project_id}`)

                
            }
            const getUsers = computed(() => {
                let setUsers = []
                Object.keys(data.users).map(function (row) {
                    let optionData = {value: data.users[row].user_id, label: data.users[row].value, disabled: true | false};
                    setUsers.push(optionData);
                })
                return setUsers
            });
            const getRoles = computed(() => {
                let setRoles = []
                data.roles.map(function (item) {
                    let optionData = {value: item.role_type_id, label: item.value, disabled: true | false};
                    setRoles.push(optionData);
                });
                return setRoles
            });

            async function getPrimaryData(URL) {
                await axios.get(URL, authHeader())
                    .then((response) => {
                        if (response.data.data) {
                            data.users = response.data.data.users
                            data.roles = response.data.data.roles
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
            async function updateRow(rowID, key, model, value, select = false) {
                if(this.getProjectData.status == 'Completed') return

                if (Object.values(value)[0] == null || Object.values(value)[0].length <= 0) {
                    toast.warning(`${Object.keys(value)[0]} is not null!`);
                    return
                }
                if (value.hasOwnProperty('author_id') && value.milestones.length == 0) {
                    data.expandRowData.items[key].author.id = null
                    toast.warning(`Please select at milestone first`);
                    return false;
                }
                
                let id = typeof key != "string" ? data.expandRowData.items[key].id : data.expandRowData.id;
                value.model = model
                value._method = "PUT"
                value.company_id = company.id

                await storeFormData({url: `${data.setURL}/${id}`}, value)
            }
            //GET DATA WHEN EXPAND ROW
            async function expandRow(row) {
                row.isShow = row.isShow ? false : true
                if (row.isShow) {
                    data.expandRowData = []
                    await openModal(false, `${data.setURL}/${row.id}`)
                        .then((res) => {
                            data.expandRowData = res.data.data
                        })
                } else {
                    data.expandRowData = []
                }
            }
            async function generateExcle(type) {
                const project = store.getters.getProjectData
                let formData = {
                    fileName: project.project_name,
                    action: vueConfig.Project.DeliverableMatrix.DVGenerateExcleEndPoint,
                    FormData: {
                        project_id: project_id,
                        generate: type
                    }
                }
                await downloadFile(formData)
                    .then((resp) => {
                        const url = URL.createObjectURL(new Blob([resp.data], {
                            type: 'application/vnd.ms-excel'
                        }))
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', formData.fileName);
                        document.body.appendChild(link);
                        link.click();
                    });
            }
            async function deleteRow(id) {
                await deleteListData(`${data.setURL}/${id}`)
                    .then(() => {
                        setParams.company_id = company.id
                        onMountedGetData()
                    })
            }

            //VALIDATION
            const schema = Yup.object().shape({
                milestone_id: Yup.string().required(),
                date: Yup.string().required()
            });
            async function formAction(value) {
                value.company_id = company.id
                value.project_id = project_id
                await storeFormData({url: vueConfig.Project.Overview.MilestoneEndPoint, modalId: 'showModal'}, value)
                    .then(() => {
                        setParams.company_id = company.id
                        onMountedGetData()
                    })
            }
            async function addMilestone() {
                await openModal('showModal', vueConfig.Setup.MilestoneEndPoint, {company_id: company.id})
                    .then((res) => {
                        data.inputFormData.milestone_id = ''
                        data.setMilestone = res.data.data
                    })
            }
            // check update permission
            data.checkUpdate = computed(() => {
                if (invitationData.value.invitation_status === 'Invited') {
                    return true
                } else if (!data.permission.canEdit) {
                    return true
                } else if(!userProfile.leader_for_projects.find(element => element == project_id)){
                    return true                                   
                } else {
                    return false
                }
            })


            return {
                data, setParams, updateRow, getUsers, getRoles, project_id, generateExcle, getProjectData,
                openModal, schema, formAction, addMilestone, deleteRow, resetForm, invitationData, expandRow
            }
        }
    }
</script>

<style scoped>

    .deliverable-matrix-data .accordion-header span svg {
        cursor: pointer;
    }


    .bg-purple-lite {
        background-color: var(--purple-lite-100);
    }
    .scrollable-content {
        width: 100%;
        height: calc(100vh - 19em);
        overflow-x: auto;
    }

    .deliverable-matrix-data p,
    .deliverable-matrix-data select {
        font-size: 0.875em;
    }
    .inner-table {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .title-table th {
        padding: 0.5em;
    }
    .row-white {
        background-color: var(--white);
    }
    .row-alternate {
        background-color: var(--border-01);
    }
    .data-block {
        min-height: 8em;
        padding: 0 0.5em;
        border-top: 1px solid var(--border-01);
        border-bottom: 1px solid var(--border-01);
    }

    .large-cell {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        background: white;
        /* padding: 0.5em; */
    }

    .matrix-content-height {
        height: 150px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .small-cell {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        background: white;
    }

    .table-head {
        min-height: 60px;
    }

    .table-subhead {
        min-height: 60px;
    }

    .table-subhead div {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    .matrix-content-height >>> .multiselect-tag{
        white-space: unset !important;
    }
    .matrix-content-height >>> .multiselect-clear {
        display: none;
    }

</style>